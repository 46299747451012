<template>
  <div>
    <h3>
      {{ id !== "" ? "Modify Template" : "Add New Communication Template" }}
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.newTemplateForm"
    />
    <ValidationObserver ref="newTemplateForm">
      <form @submit.prevent="onSubmit">
        <div v-if="!loading">
          <div class="card mb-1">
            <div class="card-header" v-b-toggle.spot-information>
              <div class="card-title mb-0">Communication Template</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <TextInput
                    name="Template Name"
                    id="templateName"
                    rules="required|min:1|max:100"
                    v-model="template.name"
                  />
                  <small class="form-text text-muted mb-2"
                    >The name associated to the template, shown in the template
                    popup where available.</small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <ValidationProvider
                    rules="required|min:1|max:1500"
                    name="Template Content"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="content"
                    >
                      Template Content
                      <span class="error">*</span>
                    </label>
                    <ckeditor
                      :config="editorConfig"
                      v-model="template.content"
                      v-bind="ariaInput"
                    />
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                  <small class="form-text text-muted mb-2"
                    >The template messaging for the template, will be populated
                    in text entry field that allows for template use.</small
                  >
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
            Save
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary mt-4 mr-2"
            @click="previewTemplate"
          >
            Preview
          </button>
          <button
            class="btn btn-lg btn-secondary mt-4 mr-2"
            @click="clear"
            type="button"
            v-show="id === ''"
          >
            Clear
          </button>
          <button
            class="btn btn-lg btn-cancel mt-4 mr-2"
            @click="cancel"
            type="button"
          >
            Cancel
          </button>
          <p class="mt-2">
            <span style="color: red;">*</span> Indicates a required field
          </p>
        </div>
        <div v-else class="text-center">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
        <AdminHtmlPreviewModal
          title="Template Preview"
          :content="template.content"
          ref="previewModal"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import AdminCommunicationTemplateService from "@/services/admin/AdminCommunicationTemplateService.js";
import AdminHtmlPreviewModal from "@/components/admin/AdminHtmlPreviewModal";
import ckconfig from "@/helpers/ckeditorConfig.js";

export default {
  name: "AdminCommunicationTemplate",
  title: "Admin - Communication Template",
  components: {
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    TextInput,
    AdminHtmlPreviewModal
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      errors: [],
      template: {
        name: "",
        content: ""
      },
      loading: false,
      editorConfig: ckconfig
    };
  },
  methods: {
    previewTemplate() {
      this.$refs.previewModal.show();
    },
    clear() {
      this.template = {
        name: "",
        content: ""
      };
      this.$refs.newTemplateForm.reset();
    },
    cancel() {
      this.$router
        .push(`/admin/communication-template-management`)
        .catch(() => {});
    },
    async getTemplate() {
      if (this.id === "") return;
      this.loading = true;
      const service = new AdminCommunicationTemplateService(this.tenantId);
      const response = await service.getTemplate(this.id);
      this.template = response.data;
      this.loading = false;
    },
    async onSubmit() {
      this.$refs.newTemplateForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.newTemplateForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.newTemplateForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            const templateService = new AdminCommunicationTemplateService(
              this.tenantId
            );
            const response = await templateService.saveTemplate(this.template);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message:
                  this.id !== ""
                    ? "Template had been Modified."
                    : "Communication template has been added.",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              setTimeout(() => {
                this.$router
                  .push(`/admin/communication-template-management`)
                  .catch(() => {});
              }, 3000);
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getTemplate();
  }
};
</script>

<style></style>
