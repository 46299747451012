const config = {
  toolbarGroups: [
    {
      name: "basicstyles",
      groups: ["basicstyles"]
    },
    {
      name: "links",
      groups: ["links"]
    },
    {
      name: "paragraph",
      groups: ["list", "blocks"]
    },
    {
      name: "styles",
      groups: ["styles"]
    },
    {
      name: "document",
      groups: ["mode"]
    }
  ],
  // Remove the redundant buttons from toolbar groups defined above.
  removeButtons:
    "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar",
  removePlugins: "elementspath",
  resize_enabled: false,
  link: {
    defaultProtocol: "http://",
    addTargetToExternalLinks: true
  },
  disableNativeSpellChecker: false
};
export default config;
